


























































































import { defineComponent } from "@vue/composition-api";
import { CellGroup, Cell, Collapse, CollapseItem, Field, Popup, Button, Divider, Uploader, Image, ImagePreview, Step, Steps } from "vant";
import LevyExport from "./levy-export.vue";
import axios from "@/helpers/axios";
import moment from "moment";
import * as qiniu from "qiniu-js";
import { imageKeyToUrl } from "@/helpers/lib";
import { getLevyStatusNameForUser } from "./levy";

moment.locale("zh-cn");

const ACTIVE = ["1"];
const INACTIVE = [] as string[];

export default defineComponent({
  data() {
    return {
      platformId: 0,
      platformName: "",
      businessId: 0,
      businessName: "",
      amount: 0,
      cardNo: "",
      cardName: "",
      bank: "",
      hasNotHandledOrder: false,
      activeRecharge: ACTIVE,
      activeExport: INACTIVE,
      fileList: [] as { file: File; url: string; key: string; isImage: boolean }[],
      qiniuToken: "",
      lastNotHandledOrder: null as null | { id: number; imageKey: string; createTime: string },
      lastHandledOrder: null as null | { id: number; amount: number; status: number; handleTime: string; imageKey: string },

      activeStep: 0,
      showExportPopup: false,
      exportPlatformId: 0,
      exportPlatformName: ""
    };
  },
  created() {
    this.loadInfo();
    this.loadQiniuToken();
  },
  methods: {
    getLevyStatusNameForUser,
    async loadInfo() {
      const res = await axios
        .post("/api/manage/levy/account-info")
        .then(res => res.data.data)
        .catch(() => undefined);
      if (!res) return;
      const { amount, cardNo, cardName, bank, platformId, platformName, businessId, businessName, hasNotHandledOrder, lastNotHandledOrder, lastHandledOrder } = res;
      this.amount = amount / 100;
      this.cardNo = cardNo;
      this.cardName = cardName;
      this.bank = bank;
      this.platformId = platformId;
      this.platformName = platformName;
      this.businessId = businessId;
      this.businessName = businessName;
      this.hasNotHandledOrder = hasNotHandledOrder;
      this.lastNotHandledOrder = lastNotHandledOrder;
      this.lastHandledOrder = lastHandledOrder;
      if (lastNotHandledOrder) {
        this.activeStep = 1;
      } else {
        if (lastHandledOrder) {
          this.activeStep = 2;
        } else {
          this.activeStep = 0;
        }
      }
    },
    async doRecharge() {
      const imageKey = this.fileList[0]?.key;
      if (!imageKey) {
        this.$toast("请上传照片");
        return;
      }
      await axios
        .post("/api/manage/levy/init-recharge-order", { imageKey: imageKey })
        .then(() => {
          this.$toast("提交成功");
          this.loadInfo().then(() => {
            this.fileList = [];
          });
        })
        .catch(() => {});
    },
    loadQiniuToken() {
      this.$axios.post("/api/manage/getQiniuToken", {}).then(res => {
        this.qiniuToken = res.data.data;
      });
    },
    onPickFile(file: { file: File; status: string; key: string }) {
      if (Array.isArray(file)) {
        for (const f of file) {
          this.onPickFile(f);
        }
        return;
      }
      file.status = "uploading";
      const options = {
        quality: 0.92,
        noCompressIfLarger: true
      };
      qiniu.compressImage(file.file, options).then(data => {
        const observable = qiniu.upload(data.dist as File, file.key, this.qiniuToken);
        observable.subscribe({
          complete(res) {
            file.status = "done";
            file.key = res.key;
          },
          error() {
            file.status = "error";
          }
        });
      });
    },
    viewImage(image: string) {
      ImagePreview({
        images: [image],
        startPosition: 0
      });
    },
    imageKeyToUrl(key: string | null | undefined) {
      return imageKeyToUrl(key);
    },
    doShowExportPopup(account: { platformName: string; platformId: number }) {
      this.showExportPopup = true;
      this.exportPlatformName = account.platformName;
      this.exportPlatformId = account.platformId;
    }
  },
  components: {
    LevyExport,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Uploader.name]: Uploader,
    [Image.name]: Image,
    [Step.name]: Step,
    [Steps.name]: Steps
  }
});
