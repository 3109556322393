export function getLevyStatusNameForUser(status: number) {
  switch (status) {
    case 0:
      return "处理中";
    case 1:
      return "已通过";
    case 2:
      return "已拒绝";
    default:
      return "未知";
  }
}

export function getLevyStatusNameForAdmin(status: number) {
  switch (status) {
    case 0:
      return "待财务核实款项";
    case 1:
      return "已通过";
    case 2:
      return "已拒绝";
    default:
      return "未知";
  }
}
